/*
 * @Description: AI助手
 * @Author: chenzhen
 * @Date: 2024-10-29 16:42:28
 * @LastEditors: chenzhen
 */
import React, { useCallback, useEffect, useState, useRef } from 'react';
import {
  CloseOutlined,
  DoubleRightOutlined,
  RightOutlined,
  DoubleLeftOutlined,
} from '@ant-design/icons';
import { Row, Col, Input, Divider, Button, Modal, Space, message } from 'antd';
import { IMAGE_LIST } from 'utils/enumList';
import { smoothScrollToBottom } from 'utils';
import { AiBarChart, AiLineChart, AiPieChart } from './AiChartComponents';
import { getAiChatAnswer, getPresetQuestion } from 'api/aiChatApi';
import { useSelector } from 'react-redux';
const { TextArea } = Input;
import { StateTypes, DataFilterTypes } from 'store/types';
import './index.scss';

interface ChartConfig {
  type?: string;
  title?: string;
  data_cols?: string[];
  category?: string;
}

interface ChartItem {
  type?: string;
  config?: ChartConfig;
  data?;
}

interface AnswerList {
  [index: number]: ChartItem;
  error?: string;
}

const AiChat = ({
  setIsOpenAiChat,
  chatList,
  setChatList,
  isAmplify,
  setAmplify,
}: {
  setIsOpenAiChat;
  chatList;
  setChatList;
  isAmplify;
  setAmplify;
}) => {
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [inputValue, setInputValue] = useState<string>('');
  const [isSendQuestion, setIsSendQuestion] = useState(false);
  const [answerList, setAnswerList] = useState<AnswerList>();
  const [isAnswerCompleted, setIsAnswerCompleted] = useState<boolean>(true);
  const [isOpenCommonProblems, setOpenCommonProblems] = useState<boolean>(true);
  const [isChartOpen, setIsChartOpen] = useState<boolean>(false);
  const [modalChartTitle, setModalChartTitle] = useState<string>('');
  const [modalContent, setModalContent] = useState<JSX.Element | undefined>();
  const [isResize, setIsResize] = useState<boolean>(false);
  const [presetQuestion, setPresetQuestion] = useState<any[]>([]);
  const scrollableContainerRef = useRef<HTMLDivElement>(null);
  const dataFilter: DataFilterTypes = useSelector((state: StateTypes) => state.dataFilter);

  const getAiChatAnswerFn = question => {
    smoothScrollToBottom(scrollableContainerRef.current, 500);
    setIsAnswerCompleted(false);
    chatList.push({ key: 'answer', content: '' });
    setChatList([...chatList]);
    getAiChatAnswer({
      question,
      start_date: dataFilter?.filter_time?.[0],
      end_date: dataFilter?.filter_time?.[1],
    }).then(res => {
      setIsAnswerCompleted(true);
      const { code, data } = res.data;
      if (code === 2000) {
        setAnswerList(data);
      } else {
        setAnswerList({ error: res.data.message || '问题超纲，无法回答，请重新输入问题' });
      }
    });
  };

  const fullScreenFn = () => {
    if (isAmplify) {
      setWidth(window.innerWidth - 30);
      setHeight(window.innerHeight - 80);
    } else {
      setWidth(window.innerWidth * 0.5);
      setHeight(window.innerHeight * 0.8);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', fullScreenFn);
    fullScreenFn();
    return () => {
      window.removeEventListener('resize', fullScreenFn);
    };
  }, [isAmplify]);

  useEffect(() => {
    smoothScrollToBottom(scrollableContainerRef.current, 500);
    getPresetQuestion().then(res => {
      const { code, data } = res.data;
      if (code === 2000) {
        setPresetQuestion(data);
      } else {
        message.error(res.data.message || '常见问题列表获取失败，请重试');
      }
    });
  }, []);

  const chooseCommonProblem = item => {
    if (!isAnswerCompleted) {
      return;
    }
    const list = chatList;
    list.push({
      key: 'question',
      content: item,
    });
    getAiChatAnswerFn(item);
    setChatList([...list]);
  };

  const sendQuestion = useCallback(
    e => {
      e.preventDefault();
      smoothScrollToBottom(scrollableContainerRef.current, 500);
      const list = chatList;
      list.push({
        key: 'question',
        content: inputValue,
      });
      const question = inputValue.trim();
      getAiChatAnswerFn(question);
      setChatList([...list]);
      setInputValue('');
    },
    [inputValue]
  );

  useEffect(() => {
    const list = chatList;
    smoothScrollToBottom(scrollableContainerRef.current, 500);
    if (answerList) {
      list[list.length - 1].content = answerList?.error || answerList;
    }
    setChatList([...list]);
  }, [answerList]);

  useEffect(() => {
    if (!inputValue || !inputValue.replace(/\s+/g, '')) {
      setIsSendQuestion(false);
      return;
    }
    setIsSendQuestion(true);
  }, [inputValue]);

  const changeValue = e => {
    setInputValue(e.target.value);
  };

  const closeAiChat = () => {
    setIsOpenAiChat(false);
    setAmplify(false);
  };

  const onclickChart = (content, index, type, title) => {
    setIsChartOpen(true);
    setModalChartTitle(title);
    let chart;
    if (type === 'BAR_CHART') {
      chart = (
        <AiBarChart
          dataList={content}
          index={`${index}-fullscreen-zzt`}
          width="1250px"
          isFullScreen={true}
        />
      );
    } else if (type === 'LINE_CHART') {
      chart = (
        <AiLineChart
          dataList={content}
          index={`${index}-fullscreen-zxt`}
          width="1230px"
          isFullScreen={true}
        />
      );
    } else if (type === 'PIE_CHART') {
      chart = (
        <AiPieChart
          dataList={content}
          index={`${index}-fullscreen-bt`}
          width="1230px"
          isFullScreen={true}
        />
      );
    }
    setModalContent(chart);
  };

  const handleContract = () => {
    setOpenCommonProblems(!isOpenCommonProblems);
    setIsResize(!isResize);
  };

  const getChatContent = () => {
    return chatList.map((item, index) => {
      let is100Width = false;
      if (typeof item?.content !== 'string') {
        const list = item?.content?.filter(i => i.type !== 'TEMPLATE');
        is100Width = list.length !== 0 && true;
      }
      return ['answer'].includes(item.key) ? (
        <Row key={index}>
          <Col span={24}>
            <div className="chat-answer-item">
              <img src={IMAGE_LIST.AiAnswer} alt="" className="title-icon" />
              {typeof item.content === 'string' ? (
                <div className="chat-item-content pre-wrap">
                  {item.content === '' ? (
                    <div className="loading-text">
                      <span className="dot1"></span>
                      <span className="dot2"></span>
                      <span className="dot3"></span>
                    </div>
                  ) : (
                    item.content
                  )}
                </div>
              ) : (
                <div className={is100Width ? 'chat-item-content width-80' : 'chat-item-content'}>
                  {item?.content?.map((i, key) => {
                    if (i.type === 'TEMPLATE') {
                      return (
                        <div key={key} className="pre-wrap">
                          {i.data}
                        </div>
                      );
                    } else {
                      return (
                        <div style={{ width: '100%' }} key={key}>
                          <div className="chart-title pre-wrap">{i?.config?.title}</div>
                          {i.data.length === 0 ? (
                            <div className="no-data">暂无数据</div>
                          ) : (
                            <div>
                              <div className="chart-btn">
                                <div
                                  className="full-screen"
                                  onClick={() =>
                                    onclickChart(i, index + key, i.type, i?.config?.title)
                                  }
                                >
                                  全屏
                                  <img src={IMAGE_LIST.fullScreenIcon} alt="" className="icon" />
                                </div>
                              </div>
                              <div className="chart-box">
                                {i.type === 'BAR_CHART' && (
                                  <AiBarChart
                                    dataList={i}
                                    index={`${index}-${key}-zzt`}
                                    width="100%"
                                    isResize={isResize}
                                    isFullScreen={false}
                                  />
                                )}
                                {i.type === 'LINE_CHART' && (
                                  <AiLineChart
                                    dataList={i}
                                    index={`${index}-${key}-zxt`}
                                    width="100%"
                                    isResize={isResize}
                                    isFullScreen={false}
                                  />
                                )}
                                {i.type === 'PIE_CHART' && (
                                  <AiPieChart
                                    dataList={i}
                                    index={`${index}-${key}-bt`}
                                    width="100%"
                                    isResize={isResize}
                                    isFullScreen={false}
                                  />
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      );
                    }
                  })}
                </div>
              )}
            </div>
          </Col>
        </Row>
      ) : (
        <Row key={index}>
          <Col span={24}>
            <div className="chat-question-item">
              <div className="chat-item-content pre-wrap">{item.content}</div>
              <img src={IMAGE_LIST.AiProblemUser} alt="" className="title-icon" />
            </div>
          </Col>
        </Row>
      );
    });
  };

  return (
    <div className="ai-chat" style={{ width, height }}>
      <div className="title-container">
        {
          <div className="title-text">
            <img src={IMAGE_LIST.AichatTitle} alt="" className="title-icon" />
            AI助手
          </div>
        }
        <div className="title-btn">
          {isAmplify ? (
            <div
              className="title-icon"
              onClick={() => {
                setAmplify(false);
                setIsResize(!isResize);
              }}
            >
              <img src={IMAGE_LIST.shrinkOutlined} alt="" className="icon" />
            </div>
          ) : (
            <div
              className="title-icon"
              onClick={() => {
                setAmplify(true);
                setIsResize(!isResize);
              }}
            >
              <img src={IMAGE_LIST.arrowsAlt} className="icon" />
            </div>
          )}
          <CloseOutlined className="title-icon" onClick={closeAiChat} />
        </div>
      </div>
      <div className="chat" style={{ height: height - 55 }}>
        <div className="chat-left">
          <div className="chat-content" ref={scrollableContainerRef}>
            {getChatContent()}
          </div>
          <Divider className="chat-divider" />
          <div className="chat-input">
            <TextArea
              className="global-input send-input"
              value={inputValue}
              placeholder="请输入您想咨询的问题"
              onChange={changeValue}
              autoSize={{ minRows: 4, maxRows: 4 }}
              onPressEnter={
                isAnswerCompleted
                  ? sendQuestion
                  : () => {
                      console.log('禁用');
                    }
              }
            />
            <Button
              className={
                isSendQuestion && isAnswerCompleted
                  ? 'global-success-btn chat-send-btn'
                  : 'global-success-btn chat-send-disabled'
              }
              onClick={sendQuestion}
              disabled={!isSendQuestion || !isAnswerCompleted}
            >
              发送
            </Button>
          </div>
        </div>
        {isOpenCommonProblems ? (
          <div className="common-problems">
            <div className="common-retract" onClick={handleContract}>
              收起
              <DoubleRightOutlined className="icon" />
            </div>
            <div className="common-list" style={{ height: height - 130 }}>
              <div className="title">
                <img src={IMAGE_LIST.AiCommonProblem} alt="" className="title-icon" /> 常见问题：
              </div>
              <ul className="list">
                {presetQuestion?.map((item, index) => {
                  return (
                    <li
                      className="item"
                      key={index}
                      onClick={() => chooseCommonProblem(item)}
                      style={!isAnswerCompleted ? { cursor: 'not-allowed' } : {}}
                    >
                      <div className="title">
                        {index + 1}.{item}
                      </div>
                      <RightOutlined />
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        ) : (
          <div className="retract-btn" onClick={handleContract}>
            常见问题
            <DoubleLeftOutlined />
          </div>
        )}
      </div>
      <Modal
        title={modalChartTitle}
        className="chart-modal"
        open={isChartOpen}
        onCancel={() => setIsChartOpen(false)}
        style={{ top: '120px' }}
        footer={
          <Space>
            <Button
              className="global-cancel-btn"
              size="middle"
              onClick={() => setIsChartOpen(false)}
            >
              关闭
            </Button>
          </Space>
        }
      >
        {modalContent}
      </Modal>
    </div>
  );
};

export default AiChat;
